<template>
  <div class="material-group" :class="{ required }" :disabled="disabled">
    <textarea
      v-if="type == 'textarea'"
      :id="id"
      :rows="rows"
      :value="value"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :style="icon ? 'padding-right: 30px;' : ''"
      :required="required"
      @input="handleInput"
    />

    <input
      v-else-if="!chips"
      :id="id"
      :type="type"
      :value="value"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :style="icon ? 'padding-right: 30px;' : ''"
      :required="required"
      :chips="chips"
      :maxlength="maxlength"
      @input="handleInput"
    >
    <div v-else class="chips">
      <span>
        {{ value }}
      </span>
    </div>

    <font-awesome-icon
      v-if="icon"
      :icon="icon"
      class="fa-lg search-email"
      @click="handleIconClick()"
    />
    <label 
      :for="id" 
      :class="{ 
        'placeholder-label': (value == '' || !value) && value != 0 || String(value).includes('*') 
      }"
    >
      <span v-if="!placeholderBicolor">{{ label }}</span>
      <span v-if="placeholderBicolor">
        <span>piattaforma</span>
        <span style="color:#c0c0c0">+alias</span>
        <span>@cving.com</span>
      </span>
      <span v-if="required" class="pin">*</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String || Number,
      default: ''
    },
    value: {
      type: String || Number,
      default: ''
    },
    placeholder: {
      type: String || Number,
      default: ''
    },
    placeholderBicolor: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    rows: {
      type: String || Number,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    chips: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    autocomplete: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER
    }
  },
  emits: [
    'input',
    'iconClicked',
    'search',
    'search:blur',
    'search:focus',
    'option:selected'
  ],
  setup(props, { emit }) {
    const handleInput = (e) => emit('input', e.target.value)
    const handleIconClick = () => emit('iconClicked')

    return {
      props,
      handleInput,
      handleIconClick
    }
  }
}
</script>
<style lang="scss" >
.placeholder-label::after,
.placeholder-label:after {
  content: '*'!important;
  color:rgb(183, 183, 183);
}

.vs__open-indicator {
  transform: rotate(0deg) scale(.7)!important;
}
.vs--open .vs__open-indicator {
    transform: rotate(180deg) scale(.7)!important;
}
</style>
<style lang="scss" scoped>
.material-group[disabled="true"] {
  label {
    color: rgb(192, 192, 192);
  }
  .chips span {
    opacity: 0.99;
  }
}
.chips {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  height: 27px;
  span {
    height: 22px;
    line-height: 22px;
    padding: 0 8px !important;
    padding-left: 8px !important;
    border: 0;
    border-radius: 10px;
    white-space: nowrap;
    display: inline-block;
    text-align: center;
    font-size: 13px;
    background: #0099ff;
  }
}

::placeholder {
  color: transparent !important;
  opacity: 0 !important; /* Firefox */
}
// :root {
// 	--material-group-padding: 5px;
// }
.material-group {
  $material-group-padding: 5px;
  position: relative;
  margin-top: 1.5rem;

  input,
  textarea,
  .v-select {
    //font-size: 18px;
    padding: $material-group-padding;
    display: block;
    border: none;
    background: transparent;
    color: white;
    border-bottom: 1px solid #ffffff9f;
    width: calc(100% - $material-group-padding);
    resize: vertical;

    &:focus {
      outline: none;
      ~ {
        .bar {
          &:before {
            width: 50%;
          }
          &:after {
            width: 50%;
          }
        }
      }
    }
  }

  span.bar {
    position: relative;
    display: block;
    width: calc(100% - $material-group-padding);
    margin-top: 1px;

    &:before {
      left: 50%;
    }

    &:after {
      right: 50%;
    }

    &:after,
    &:before {
      content: "";
      height: 2px;
      width: 0;
      bottom: 1px;
      position: absolute;
      background: var(--accented);
      transition: 0.2s ease all;
    }
  }

  label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
    top: -20px;
    font-size: 14px;
    left: 2px;
    color: var(--accented);
    transition: top 0.5s ease-in-out;
  }
 /*  .placeholder-label {
    top: 6px !important;
    transition: top 0.1s ease-in;
  } */

  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 0;
  }

  &.required {
    label {
      .pin {
        //color: #dc3545 !important;
        font-size: 20px;
        position: absolute;
        top: -3px;
        right: -10px;
      }
    }

    span.bar.active {
      height: 2px;
      margin-top: -1px;
      background: rgb(242, 83, 83);

      &:before,
      &:after {
        background: #dc3545;
      }
    }
  }
}
</style>
