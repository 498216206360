import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: '',
    component: () => import('../views/Welcome.vue'),
    redirect: { name: 'Welcome' }
  },
  {
    path: '/checkCandidate',
    name: 'CheckCandidate',
    component: () => import('../views/CheckCandidate.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/companiesList',
    name: 'CompaniesList',
    component: () => import('../views/CompaniesList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/campaignsList',
    name: 'CampaignsList',
    component: () => import('../components/companiesList/campaigns/CampaignsList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/billingDocuments',
    name: 'BillingDocuments',
    component: () => import('../views/BillingDocuments.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/coupons',
    name: 'Coupons',
    component: () => import('../views/Coupons.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/jobAdvertisement',
    name: 'Verica annunci',
    component: () => import('../views/jobAdvertisement.vue')
  },
  {
    path: '/shares',
    name: 'Condivisioni',
    component: () => import('../views/Shares.vue')
  },
  {
    path: '/jobAdvertisement',
    name: 'Verica annunci',
    component: () => import('../views/jobAdvertisement.vue')
  },
  {
    path: '/shares',
    name: 'Condivisioni',
    component: () => import('../views/Shares.vue')
  },
  {
    path: '/checkPayments',
    name: 'CheckPayments',
    component: () => import('../views/CheckPayments.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/newCompany',
    name: 'NewCompany',
    component: () => import('../components/companiesList/companies/CompanyManagement.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logs',
    name: 'Logs',
    component: () => import('../views/Logs.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/marketing',
    name: 'Marketing',
    component: () => import('../views/Marketing.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/accounting/references',
    name: 'References',
    component: () => import('../views/accounting/References.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/accounting/unlockingActions',
    name: 'Unlocking Actions',
    component: () => import('../views/accounting/UnlockingActions.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/accounting/companies',
    name: 'Companies',
    component: () => import('../views/accounting/Companies.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/accounting/report',
    name: 'Report',
    component: () => import('../views/sales/Report.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transfersLogs',
    name: 'Transfer Logs',
    component: () => import('../views/TransferLogs.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    // only admin
    path: '/permissions',
    name: 'Permessi',
    component: () => import('../views/Permissions.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/jobboards',
    name: 'Job Boards',
    component: () => import('../views/JobBoards.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/referrals',
    name: 'Referrals',
    component: () => import('../views/Referrals.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/archive',
    name: 'Archive',
    component: () => import('../views/Archive.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/suggestors',
    name: 'Suggestors',
    component: () => import('../views/Suggestors.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('../views/Unauthorized.vue')
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('../views/Welcome.vue')
  },
  {
    path: '/createProject',
    name: 'Crea Progetto',
    component: () => import('../views/Project.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/createProjectUnique',
    name: 'Crea Progetto Elena',
    component: () => import('../views/ProjectUnique.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/allProjects',
    name: 'Tutti i progetti',
    component: () => import('../views/AllProjects.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/nowhereindeedumana',
    name: 'Tutti i progetti Umana',
    component: () => import('../views/NoWhereIndeedUmana.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cms/boxLanci',
    name: 'Box lanci',
    component: () => import('../views/CMS/boxLanci.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cms/banner',
    name: 'Banner',
    component: () => import('../views/CMS/banner.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cms/eventi',
    name: 'Eventi',
    component: () => import('../views/CMS/eventi.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cms/articoli',
    name: 'Articoli',
    component: () => import('../views/CMS/articoli.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cms/opportunita',
    name: 'Opportunità',
    component: () => import('../views/CMS/opportunita.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cms/aziende',
    name: 'Aziende suggerite',
    component: () => import('../views/CMS/aziende.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cms/social',
    name: 'I nostri social',
    component: () => import('../views/CMS/social.vue'),
    meta: {
      requiresAuth: true
    }
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (localStorage.getItem('userId') !== null) {
      // if (to.matched.some((record) => record.meta.permissions)) { 
      //   checkPermissions(to.matched[0].meta.permissions, next)
      // } else {
      // if the user is stagno let access only accounting pages
      if (localStorage.getItem('userEmail') === 'stagno@cving.com') {
        if (to.path.includes('accounting')) {
          next()
        } else {
          next({ name: 'Login' })
        }
      } else {
        next() // go to wherever I'm going
      }
      // }
    } else {
      next({ name: 'Login' })
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})
// const checkPermissions = (permissions, next) => {
//   console.log(permissions)
//   if (localStorage.getItem('userEmail') === 'bosetti@cving.com') {
//     next()
//   } else {
//     next({ name: 'Login' })
//   }
// }
export default router
